<!-- Template -->
<template>
  <svg
    class="i-check"  
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height=" height"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path
      id="check"
      d="M78.588,90.629l-7.915,7.919L67.055,94.93a1.093,1.093,0,0,0-1.545,1.545l4.391,4.391a1.093,1.093,0,0,0,1.545,0l8.687-8.687a1.093,1.093,0,0,0-1.545-1.545Z"
      transform="translate(-65.212 -90.336)"
      :fill="fill"
    />
  </svg>
</template>

<!-- Scripts -->
<script>
// Componente
export default {
  // Nome do componente
  name: "IconCheck",
  // Propriedades herdadas
  props: {
    width: { type: Number, default: 15.22 },
    height: { type: Number, default: 10.85 },
    fill: { type: String, default: '#ffb800' },
  },
}
</script>